export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const currentUser = await getCurrentUser();
  const isLoggedIn = userStore.signedIn;

  if (isLoggedIn && currentUser.emailVerified) {
    return navigateTo("/system");
  } else if (isLoggedIn && !currentUser.emailVerified) {
    return navigateTo("/verify");
  }
});
